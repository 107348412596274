let servicesHost = "http://localhost:7071/api";
let stripe = {
    pk: 'pk_test_51IrE41ECbliKBFAnnK0Q0IJ6EbTkg30VXkOnP6w0Gj5NyDF55Krlrc9KuSVqPssC4tn384R6Pgw8K97Ayl2GdJJm00iLzCEgmu',
    yearly_priceId: 'price_1IrE9CECbliKBFAn7n11vbgn',
    monthly_priceId: 'price_1IzC74ECbliKBFAnnSm1NRdR'
};


let auth0 = {
    host : "https://dev-scratchtime.au.auth0.com", 
    redirect: "http://local.scratchtime.golf:3000", 
    clientId : "bRqSPrrkkUX0aojkHJIBQLZjdrINmTpG"
};

let auth0Prod = {
    host : "https://scratchtime.au.auth0.com", 
    redirect: "http://viewer.scratchtime.golf", 
    clientId : "Uz0gQLYCSb2DMzaCcX7prEBwYMHyTPMS"
};

const hostname = window && window.location && window.location.hostname;

// Local development
if(hostname === 'localhost') 
{
    servicesHost = "http://localhost:7071/api";
    //servicesHost = "https://scratchtime-api-staging.azurewebsites.net/api";
    //servicesHost = "https://scratchtime-api.azurewebsites.net/api";
}
// Production environment
else {
    servicesHost = "https://scratchtime-api.azurewebsites.net/api";
    stripe = {
        pk: 'pk_live_51IrE41ECbliKBFAnkaCxGsp7japltlgMcty8rSz8sCiSiPF80o9wWDIqDphbQJ2anxwodHvfgTCtgTh7o3Wn7Tn200D86Xja91',
        yearly_priceId: 'price_1JMjsMECbliKBFAnGtHoUH5z',
        monthly_priceId: 'price_1JMjsBECbliKBFAndahPlSoL'
    };

    auth0 = auth0Prod;
}

export {servicesHost, stripe, auth0}