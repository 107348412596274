import React from 'react';
import {DoubleSection} from "../layout/DoubleSection";
import {SingleSection} from "../layout/SingleSection";
import OwlCarousel from "react-owl-carousel";
import {PricingCricket} from "../PricingCricket";
import LogoText from "../logoText";

const CricketNew = () => {
    return (
        <>
            <section id="home_cricket" className="atf-cover-bg atf-cover-bg atf-hero-area atf-align-items-details aft-width-two" style={{ backgroundImage: "url(ipad_batting_frame.png)", backgroundSize: "cover", backgroundPosition: "center center" }}>
                <div className="container">
                  <div className="row clearfix align-self-center">

                    <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                      <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                        <div className="col-12 atf-single-details text-lg-left text-center" style={{ padding: "10px" }}>
                          <img src="/assets/img/KCC_logo.png" style={{width: '120px', marginTop: '30px'}}  alt={"Kew Logo"}/>
                          <h1 style={{fontSize: '42px', color: 'white', paddingTop:'5px', marginBottom: '30px'}}>Success at<br/>Kew Cricket Club</h1>
                        </div>

                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                      <div className="text-center" style={{backgroundColor: 'white', height: '400px', paddingTop: '200px', marginBottom: '30px'}}>
                        Kew Video
                      </div>
                      <div className="text-center" >
                      <a style={{backgroundColor:"white", color:"var(--thm-black)", margin:"10px", borderRadius:"10px", fontSize:"18px", padding:"10px 35px"}} href={'/kewccvideo'} alt={"Watch video"}>Watch video</a>
                      </div>
                    </div>
                  </div>
                </div>
            </section>
          <SingleSection>
            <div className="text-center">
              <p style={{fontSize: '26px', marginTop: '10px', lineHeight:'1.5', marginBottom: '35px'}}>Kew use <LogoText /> across 9 senior and 15 junior teams, helping players develop individual skills to achieve the ultimate team success.</p>
              <a style={{backgroundColor:"var(--thm-black)", margin:"10px", borderRadius:"10px", fontSize:"18px", color:"white", padding:"10px 35px"}} href={'/kewccvideo'}  alt={"Watch video"}>Watch video</a>
            </div>
          </SingleSection>
          <DoubleSection col1={
            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
              <div style={{ margin: '30px 10px 10px 10px' }}>
                <h2 style={{ marginBottom: "10px", fontSize: '42px', lineHeight: "1.5" }}>Real-time visual feedback</h2>
              </div>
              <OwlCarousel className='owl-theme' loop={true} margin={10} nav={false} autoplay={true} items={1} dots={true}>
                <div className='item text-center'>
                  <div className="text-center" style={{backgroundColor: '#EEEEEE', height: '400px', width: '100%', paddingTop: '200px', border: '1px solid #666666'}}>
                    Image of phone behind bowler/thrower
                  </div>
                  <div style={{width: '100%', padding: '10px'}}><h3 style={{color: 'yellowgreen',  marginBottom: '3px'}}>Batting</h3>Kew's coaches stand outside the net and use a tablet to give feedback to the batter</div>
                </div>
                <div className='item text-center'>
                  <div className="text-center" style={{backgroundColor: '#EEEEEE', height: '400px', width: '100%', paddingTop: '200px', border: '1px solid #666666'}}>
                    Image of phone behind bowler split with image side on
                  </div>
                  <div style={{width: '100%', padding: '10px'}}><h3 style={{color: 'yellowgreen',  marginBottom: '3px'}}>Bowling</h3>With a tablet in the follow though, coaches can work on bowling technique in real-time</div>
                </div>
                <div className='item text-center'>
                  <div className="text-center" style={{backgroundColor: '#EEEEEE', height: '400px', width: '100%', paddingTop: '200px', border: '1px solid #666666'}}>
                    Image of player throwing
                  </div>
                  <div style={{width: '100%', padding: '10px'}}><h3 style={{color: 'yellowgreen',  marginBottom: '3px'}}>Fielding</h3>Coaches take the phone and tablet out on the ground</div>
                </div>
                <div className='item text-center'>
                  <div className="text-center" style={{backgroundColor: '#EEEEEE', height: '400px', width: '100%', paddingTop: '200px', border: '1px solid #666666'}}>
                    Image of coach going though video with player
                  </div>
                  <div style={{width: '100%', padding: '10px'}}><h3 style={{color: 'yellowgreen',  marginBottom: '3px'}}>Analysis</h3>Coaches pause the replay and go through frame-by-frame</div>
                </div>
                <div className='item text-center'>
                  <div className="text-center" style={{backgroundColor: '#EEEEEE', height: '400px', width: '100%', paddingTop: '200px', border: '1px solid #666666'}}>
                    Screenshot of paused video
                  </div>
                  <div style={{width: '100%', padding: '10px'}}><h3 style={{color: 'yellowgreen',  marginBottom: '3px'}}>Instant feedback</h3>Players immediately see how changes they make are affecting their technique</div>
                </div>
              </OwlCarousel>
            </div>
          }
                         col2={<div className="atf-single-about-img my-auto wow fadeIn align-self-center text-center text-lg-start" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">

                           <p style={{ fontSize: "18px", marginTop: "25px" }}>Kew connect <LogoText /> to a tablet for one-on-one coaching, allowing them to pause the replay and go through key frames with the player, in real-time.</p>
                           <p style={{ fontSize: "18px", marginTop: "25px" }}>Coaches can show technique issues <span style={{color: 'var(--thm-black)'}}>on the spot</span>, and the player gets instant feedback as to whether they are improving.</p>
                           <p>&nbsp;</p>


                         </div>}
          />
          <SingleSection background={'#F1F8E4'}>
            <div className="text-center">
              <div style={{color: 'yellowgreen', fontSize: '26px', marginTop: '10px'}}>"Seeing the replay instantly is invaluable. <br />Players can watch, adapt, and improve, on the spot."</div>
              <div style={{color: 'yellowgreen', fontSize: '16px', marginTop: '15px'}}>- Quote -</div>
            </div>
          </SingleSection>
            <DoubleSection col1={
                <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                    <div style={{ margin: '30px 10px 10px 10px' }}>
                        <h2 style={{ marginBottom: "10px", fontSize: '42px', lineHeight: "1.5" }}>Delayed replay in the nets</h2>
                    </div>
                    <p style={{ fontSize: "18px", marginTop: "25px" }}>Kew connect <LogoText /> to a battery powered TV and set it up in the main net.</p>
                  <p style={{ fontSize: "18px", marginTop: "25px" }}>Batters can view an instant replay after they play their shot, and bowlers can review their action in their follow through.</p>
                  <p>&nbsp;</p>
                </div>
            }
                           col2={<div className="atf-single-about-img text-center my-auto wow fadeIn align-self-center" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                             <OwlCarousel className='owl-theme' loop={true} margin={10} nav={false} autoplay={true} items={1} dots={true}>
                               <div className='item text-center'>
                                 <div className="text-center" style={{backgroundColor: '#EEEEEE', height: '400px', width: '100%', paddingTop: '200px', border: '1px solid #666666'}}>
                                   Image of batter
                                 </div>
                                 <div style={{width: '100%', padding: '10px'}}><h3 style={{color: 'yellowgreen',  marginBottom: '3px'}}>Batting</h3>Kew place a portable TV behind the net</div>
                               </div>
                               <div className='item text-center'>
                                 <div className="text-center" style={{backgroundColor: '#EEEEEE', height: '400px', width: '100%', paddingTop: '200px', border: '1px solid #666666'}}>
                                   Image of bowler
                                 </div>
                                 <div style={{width: '100%', padding: '10px'}}><h3 style={{color: 'yellowgreen',  marginBottom: '3px'}}>Bowling</h3>Placing the TV in the follow through to view bowling action</div>
                               </div>
                             </OwlCarousel>


                           </div>}
                     />
          <SingleSection background={'#F1F8E4'}>
            <div className="text-center">
              <div style={{color: 'yellowgreen', fontSize: '26px', marginTop: '10px'}}>"We use this technology at indoor training facilities, and now we have it at our club"</div>
              <div style={{color: 'yellowgreen', fontSize: '16px', marginTop: '15px'}}>- Quote -</div>
            </div>
          </SingleSection>

          <DoubleSection
            col1={<div className='item text-center'>
              <div className="text-center" style={{backgroundColor: '#EEEEEE', height: '400px', width: '100%', paddingTop: '200px', border: '1px solid #666666'}}>
                Image using app, coach showing video to player
              </div>
            </div>}
            col2={<div className="atf-single-about-img text-center my-auto wow fadeIn align-self-center"><div style={{color: 'var(--thm-black)', fontSize: '26px', padding:'20px'}}>"I recommend PracticeLoop to any club. It really helps us build skills across our entire club"</div>
              <div style={{color: 'var(--thm-black)', fontSize: '16px', marginTop: '15px', marginBottom: '35px'}}>- Quote -</div>
              <a href={'/cricket'} alt={"More cricket"}><div style={{backgroundColor:"yellowgreen", color:"white", margin:"10px", borderRadius:"10px", fontSize:"18px", padding:"10px 35px"}} >Learn more about<br />PracticeLoop for cricket</div></a>
          </div>}
          />
          <p style={{height: '80px'}}>&nbsp;</p>
          <SingleSection background={'yellowgreen'}>
            <div className="container">
              <div className="row clearfix align-self-center">
                <div className="col-lg-2 col-md-1 col-0"></div>
                <img className="col-lg-1 col-md-2 col-3" src="./tripod_special.png" style={{marginTop: '-100px', marginBottom: '-45px'}} alt="Real time view" ></img>

                <div className="col-lg-7 col-md-8 col-9 align-self-center text-center">
                  <h1 style={{color: 'var(--thm-black)', fontWeight: 'bold', fontSize: '42px', marginBottom: '20px'}}>Try it at your club</h1>
                  <p style={{color: 'white', fontSize: '22px', marginBottom: '5px'}}>Give your club the edge over the opposition.</p>
                  <p style={{color: 'var(--thm-black)', fontSize: '16px', marginBottom: '30px'}}>Get PracticeLoop for your club and we'll include this portable tripod for free.</p>

                  <a style={{backgroundColor:"var(--thm-black)", margin:"10px", borderRadius:"10px", fontSize:"18px", color:"white", padding:"10px 35px"}} href={'#features-cricket'}>Club Deal</a>
                </div>
                <div className="col-lg-2 col-md-1 col-0"></div>
              </div>
            </div>
          </SingleSection>

          <DoubleSection
            col1={
              <div className="atf-single-about-img text-center" >
                <div style={{ margin: '30px 10px 10px 10px' }}>
                  <h2 style={{ marginBottom: "10px", fontSize: '42px', lineHeight: "1.5" }}>Proven technology,<br /><span style={{ color: "yellowgreen" }}>now on your phone.</span></h2>
                </div>
                <p style={{ fontSize: "18px", marginTop: "25px" }}>Delayed video technology has been used in indoor nets for years, and it really helps.</p>
                <p style={{ fontSize: "18px", marginTop: "10px" }}>PracticeLoop allows you to take this technology to where you train.</p>
                <p>&nbsp;</p>
              </div>

            }

            col2={
              <div className="atf-single-about-img text-center" >
              <img src="./phone_batting_frame.png" alt="Video on your phone" style={{ width: "300px" }} />
              </div>

            }
          />
          <SingleSection background={'#F1F8E4'}>
            <div className="text-center">
              <h2 style={{marginBottom: '40px'}}>Give your club and edge over the opposition</h2>
              <a style={{backgroundColor:"yellowgreen", color:"white", margin:"10px", borderRadius:"10px", fontSize:"18px", padding:"10px 35px"}} href={'/pack/signup'} alt={"Try free for 7 days"}>Try free for 7 days</a>
            </div>
          </SingleSection>
            <PricingCricket />

          <SingleSection>
            <div className="text-center">
            <h3 className="text-center" style={{marginBottom: '35px'}}>Learn more about PracticeLoop for cricket</h3>
            <a style={{backgroundColor:"yellowgreen", color:"white", margin:"10px", borderRadius:"10px", fontSize:"18px", padding:"10px 35px"}} href={'/cricket'} alt={"More cricket"}>Cricket home</a>
            </div>
          </SingleSection>

        </ >
    );
}

export default CricketNew;

