import React from 'react';
import { androidAppDownload, iphoneAppDownload } from './link-actions';

function SetupGuide() {
    return (<>
        <div className="non-landing-banner"></div>
        <section id="setup-guide" className="atf-about-area atf-section-padding atf-shape">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-6 col-12">
                        <div className="atf-section-title text-center">
                            <h2>Setup guide</h2>
                            <div className="animated-line"></div>
                            <p>Watch the video for setup instructions</p>
                            <video id="loopvideo" preload="metadata" style={{ marginTop: '25px', width: "100%", maxWidth: "400px", boxShadow: '0px 0px 20px 10px #66666666' }}
                                controls={true} autoPlay={false} muted={false} loop={false} playsInline={true}>
                                <source src="/setup_video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-12">
                        <div className="atf-section-title text-center">
                            <div className="setup-guide-content">
                                <h2 id="phone">Step 1</h2>
                                <h3>Create Wifi connection</h3>
                                <i className="fa fa-mobile-alt"></i><i className="fa fa-wifi"></i><i className="fa fa-tablet-alt"></i>
                                <p>PracticeLoop streams video from your phone, to a second device.  To start with, you need to connect the two devices via Wifi.</p>
                                <h5>1. Set up a Wifi hotspot on your phone</h5>
                                <div>
                                    <div className="atf-section-title atf-feature-content col-12">
                                        <div class="atf-single-feature">
                                            <strong>IPhone</strong><br /><i>Settings &gt; Personal Hotspot &gt; Allow Others to Join</i><br />
                                            <a href="https://support.apple.com/en-au/HT204023" target="_blank" rel="noopener noreferrer">Further instructions</a>
                                        </div>
                                    </div>
                                    <div className="atf-section-title atf-feature-content col-12">
                                        <div class="atf-single-feature">
                                            <strong>Android</strong><br /><i>Settings &gt; Connections &gt; Mobile Hotspot and Tethering &gt; Mobile
                            Hotspot</i><br />
                                            <a href="https://support.google.com/android/answer/9059108?hl=en" target="_blank" rel="noopener noreferrer">Further
                            instructions</a>
                                        </div>
                                    </div>
                                </div>
                                <h5>2. Connect second device</h5>
                                <p>Connect your second device to the Wifi hotspot you created on your phone.</p>
                                <p>The second device can be a tablet, laptop, or another phone.</p>

                                <h2 id="phone">Step 2</h2>
                                <h3>Set up app</h3>
                                <i className="fa fa-mobile-alt"></i>
                                <p>Install and open the PracticeLoop app on your phone<br />
                                Click "Start"</p>
                                <p>At this point you should see a QR code.</p>
                                <div className="col-12 text-center cta-panel">
                                    <a href="#none" onClick={() => iphoneAppDownload('setup')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                                    <a href="#none" onClick={() => androidAppDownload('setup')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                                </div>
                                

                                <h2 id="phone">Step 3</h2>
                                <h3>View video on second device</h3>
                                <i className="fa fa-mobile-alt"></i><i className="fa fa-tablet-alt"></i><i className="fa fa-laptop"></i>
                                <p>Scan the QR code that is displayed on the app with your second device<br />
                                If your device doesn't support QR codes, type the URL in to a web browser.</p>
                                
                                <h2 id="phone">You're done</h2>
                                <p>At this point you should see a real-time and instant replay on your second device.  If you don't, please ensure:</p>
                                <ul>
                                    <ol>The second device is connected via Wifi to your phone</ol>
                                    <ol>The app is running on your phone</ol>
                                    </ul>
                                <p>If you still have no luck, please email us and we'll help you:<br />
                                help@ormond-code.com</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </>

    );
}

export default SetupGuide;

