import React from 'react';

import { loadStripe } from '@stripe/stripe-js';

import { servicesHost, stripe } from './environment'
import ProCompareTable from './components/proCompareTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faCheck, faLock } from '@fortawesome/free-solid-svg-icons'

import { telemetryService } from './telemetryService';

const MONTHLY_PRICE_ID = stripe.monthly_priceId; // Monthly subscription AUD
const YEARLY_PRICE_ID = stripe.yearly_priceId; // Yearly subscription AUD

const viewerShopStyle = {
    fontSize: '12px'
}

const subChoiceStyle = {
    backgroundColor: "white",
    border: "1px solid #783F8E",
    float: "left",
    borderRadius: "10px",
    width: "48%",
    margin: '2px',
    cursor: 'pointer'
}

class ViewerShop extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: null,
            error: false,
            subscription: 'y'
        };

        this.onUpgradeClick = this.onUpgradeClick.bind(this);
        this.createCheckoutSession = this.createCheckoutSession.bind(this);
        this.selectSubscription = this.selectSubscription.bind(this);

        telemetryService.trackMilestone('Viewed viewer shop.')
        window.gtag('event', 'view_item_list', { item_list_name : 'trial_selection', items : [
            { item_id: MONTHLY_PRICE_ID, item_name : 'Monthly trial', currency: 'AUD', price: 5.00},
            { item_id: YEARLY_PRICE_ID, item_name : 'Yearly trial', currency: 'AUD', price: 29.00}]});

    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);

        if (params.has('t')) // t for token
        {
            this.setState({ token: params.get('t') });
        }
    }

    createCheckoutSession(priceId) {
        return fetch(`${servicesHost}/payments/create-checkout-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.state.token}`
            },
            body: JSON.stringify({
                priceId: priceId
            })
        }).then(function (result) {
            if (result.ok) {
                return result.json();
            }

            throw Error("Could not create checkout session.")
        });
    };

    async onUpgradeClick() {
        const stripeApi = await loadStripe(stripe.pk);
        const setError = () => this.setState({ error: true });
        let item = this.state.subscription === "m" ? {priceId: MONTHLY_PRICE_ID, cost: 5} : {priceId: YEARLY_PRICE_ID, cost: 29};

        // You'll have to define PRICE_ID as a price ID before this code block
        this.createCheckoutSession(item.priceId).then(function (data) {
            // Call Stripe.js method to redirect to the new Checkout page
            telemetryService.trackMilestone('Begin checkout.')
            window.gtag("event", "begin_checkout", { currency: 'AUD', value: item.cost, items: [{item_id: item.priceId, price: item.cost}]});

            stripeApi
                .redirectToCheckout({
                    sessionId: data.sessionId
                })
        })
            .catch(function (e) {
                setError();
            });
    }

    selectSubscription(subs) {
        this.setState({ subscription: subs })
    }

    render() {
        var error = this.state.error ? <div style={{color: 'white', backgroundColor: 'red', padding: '5px'}}>There has been an error checking out, please try again later.</div> : <span></span>

        if (this.state.token) {
            // If there is a token then there is a user, so we can let them buy a subscription
            return (
                <div style={viewerShopStyle}>
                    {error}
                    <h2 style={{ color: "#783F8E", textAlign: 'left', margin: '10px 0px 20px 3px' }}><span style={{ fontSize: '20px' }}><FontAwesomeIcon icon={faShoppingCart}></FontAwesomeIcon></span>&nbsp;&nbsp;Try for 7 days</h2>
                    <p style={{ textAlign: 'left', marginLeft: '4px', fontSize: '14px',color: "#666666"  }}>Improve faster with a Pro subscription. <strong>Free for 7 days.</strong></p>
                    <ProCompareTable></ProCompareTable>
                    <div style={{ marginTop: '15px', marginBottom: '5px' }}>
                        <h3 style={{ color: "#783F8E", margin: '25px 0px 5px 0px' }}>Choose a subscription. Cancel any time.</h3>
                        <div style={{ color: "#666666", margin: '0px 0px 8px 0px' }}>Cancel in the first 7 days and pay nothing.</div>
                        <SubChoice price={5} currency="AUD" period="Monthly" onClick={() => this.selectSubscription('m')} selected={this.state.subscription === "m"}></SubChoice>
                        <SubChoice price={29} currency="AUD" period="Yearly" onClick={() => this.selectSubscription('y')} selected={this.state.subscription === "y"}></SubChoice>
                        <div style={{clear: "both"}}></div>
                    </div>
                    <button className="premium-button" onClick={this.onUpgradeClick}><FontAwesomeIcon icon={faLock}></FontAwesomeIcon>&nbsp;&nbsp;START TRIAL</button>
                </div>)
        }

        // No token, so can't buy anything here
        return <div>Please buy pro subscription through the ScratchTime viewer</div>
    }
}

const SubChoice = props => {
    let baseStyle = props.selected ? { ...subChoiceStyle, backgroundColor: "#b0f3b0" } : subChoiceStyle;
    let check = props.selected ? <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> : <span></span>

    return (<div onClick={props.onClick} style={baseStyle}>
        <div style={{ display: "inline-block", width:"36px", fontSize: "26px", color:"green", marginLeft: "-35px"}}>{check}</div>
        <div style={{ display: "inline-block", fontSize: "26px", margin: "10px 2px 0px 2px" }}>${props.price}</div>
        <div style={{ display: "inline-block", textAlign: "left"}}>
            <div style={{fontSize: "8px"}}>{props.currency}</div>
            <div style={{fontSize: "13px"}}>{props.period}</div>
        </div>
        <div style={{color: '#333333', margin: '3px'}}>+ 7 days free</div>
    </div>)
}

export default ViewerShop