import React, { } from 'react';
import { iphoneAppDownload, androidAppDownload, iphoneRedeemCode } from '../link-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptop, faMobileAlt, faTabletAlt, faArrowCircleRight, faRedo, faGift, faCross, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import LogoText from '../components/logoText';

export const GolfVideo = () => {
    return (
        <>
            <div className="non-landing-banner"></div>
            <section className="atf-about-area atf-section-padding atf-shape">
                <div className="container">

                    <div className="row clearfix align-self-center">

                        <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h2 style={{ marginBottom: "10px", fontSize: '30px'}}><span style={{color:"yellowgreen"}}>Don't record your swing.</span> Stream it!</h2>
                                <div style={{borderTop: "2px dotted yellowgreen", padding: "5px", marginBottom: "20px"}}>
                                <p style={{ fontSize: '18px', color: "var(--thm-black)"}}>Professional video feedback, <br />For the price of a golf ball</p>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                            <img src="hero_transparent_2.png" alt="about" className="card-s" />
                        </div>
                    </div>

                </div>
            </section>

            <div className="container">
                <div className="row" style={{ border: "2px solid yellowgreen", backgroundColor: "#F1F8E4", margin: "5px", padding: "20px 0 20px 0", borderRadius: "10px" }}>
                    <div className="col-12 align-self-center text-center">
                        <FontAwesomeIcon icon={faGift} style={{ color: 'var(--thm-black)', fontSize: '60px', marginBottom: "10px" }}></FontAwesomeIcon>
                        <h3 style={{fontSize:'20px'}}>PRESIDENTS CUP SPECIAL - 90 DAYS FREE</h3>
                        <h3 style={{fontSize:'18px'}}>THEN ONLY $1.99* <span style={{fontSize: "12px"}}>/month</span></h3>
                        <div style={{ color: "#888888", margin: "10px" }}>Redeem code <span style={{ color: "#783F8E" }}>PRESCUP2024</span> to get a 90 day free trial of our PRO subscription. Click below to redeem.</div>
                        <a href="#" onClick={() => iphoneRedeemCode('PRESCUP2024')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                        <a href="/promo"><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                        <div style={{ color: "#888888", fontSize: "12px" }}>* USD. Prices may vary slightly per region. Cancel any time. Offer expires Sept 30 2024.</div>
                    </div>
                </div>
            </div>

            <section id="batting_cricket" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row clearfix align-self-center">

                        <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <div style={{ margin: '30px 10px 10px 10px' }}>
                                    <h2 style={{ marginBottom: "10px", fontSize: '26px', lineHeight: "1.5" }}>Stream your swing<br /><span style={{ color: "#999999" }}>from your</span> <span style={{ color: "yellowgreen" }}>phone,</span><br /><span style={{ color: "#999999" }}>to a </span><span style={{ color: "yellowgreen" }}>second device</span></h2>
                                </div>
                                <p style={{ fontSize: "16px", marginTop: "25px" }}>See an instant replay right at your feet</p>
                                <p>&nbsp;</p>
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-12 text-center  align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <img src="./watch_at_feet.png" alt="Replay at your feet" style={{ width: "100%", maxWidth: "800px" }} ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="who-for" className="atf-setup-area atf-section-padding">
                <div className="container">
                    <div className="row clearfix align-self-center">
                        <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h2 style={{ margin: '0px 10px 10px 10px', fontSize: '26px', lineHeight: "1.5", color: "yellowgreen"}}>A replay at your feet</h2>
                                <h2 style={{ margin: '20px 10px 20px 10px', fontSize: '20px', lineHeight: "1.5", color: "var(--thm-black)"}}>Never walk back to your phone to watch the recording again!</h2>
                                <div style={{backgroundColor:"white", borderRadius: "15px", padding: "0 5px 0 5px", border: "1px solid yellowgreen"}}>
                                <p style={{ fontSize: "16px", margin: "30px 0 20px 0", lineHeight: "2" }}>
                                    <FontAwesomeIcon icon={faTimes} style={{color:"red"}} /> Don't stop practicing to watch the recording <br />
                                    <FontAwesomeIcon icon={faTimes} style={{color:"red"}} /> Don't fumble with your phone between shots <br />
                                </p>
                                <p style={{ fontSize: "18px", margin: "5px 0 20px 0", lineHeight: "2" }}>
                                    <span style={{color:"yellowgreen",}}><FontAwesomeIcon icon={faCheck} /> Get fast feedback by watching the replay immediately after hitting your shot</span>
                                </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <img src="./driving_range_pov.png" alt="See instant replay at your feet" style={{ width: "100%", maxWidth: "800px", marginTop: "20px" }} ></img>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="atf-section-padding" style={{ paddingTop: "0px", backgroundImage: 'url(assets/img/bg/bg-3b.png)', backgroundSize: 'cover', backgroundPosition: 'center center', paddingBottom: "0px" }}>
                <div className="container">
                    <div className="row justify-content-center" style={{backgroundColor: "var(--thm-black)"}}>
                        <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <div style={{ margin: '30px 10px 10px 10px' }}>
                                    <h2 style={{ marginBottom: "10px", fontSize: '26px', lineHeight: "1.5", color:"white" }}>Fast feedback is fast improvement</h2>
                                </div>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center clearfix">
                        <div className="col-lg-6 col-md-6 col-12 atf_home_img text-center">
                            <div className="atf-img-inner wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.4s" data-wow-offset="0">
                                <div className="home-image-box">
                                <p style={{ fontSize: "16px", marginBottom:"20px" }}><strong><LogoText light={false}/></strong> <span style={{color:"#999999"}}>speeds up your</span>
                                <span style={{ color:"yellowgreen", fontStyle:"italic"}}> Practice Loop</span></p>
                                    <div>
                                        <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'var(--thm-color)', fontSize: '60px' }}></FontAwesomeIcon>
                                        <h3 className="shake-1">Swing</h3>
                                        <p>Swing in front of your phone camera</p>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faRedo} className="loopSpinner"></FontAwesomeIcon>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faLaptop} style={{ color: 'var(--thm-color)', fontSize: '40px', marginBottom: '5px' }}></FontAwesomeIcon>
                                        <FontAwesomeIcon icon={faTabletAlt} style={{ color: 'var(--thm-color)', fontSize: '60px', margin: '0px 20px 0 20px' }}></FontAwesomeIcon>
                                        <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'var(--thm-color)', fontSize: '40px', margin: '0px 25px 5px 5px' }}></FontAwesomeIcon>
                                        <h3 className="shake-2">Watch. Learn. Improve</h3>
                                        <p>Watch immediate replay on a second device</p>

                                <p style={{ fontSize: "14px", margin: "30px 0 10px 0", lineHeight: "1.8", border: "2px solid yellowgreen", backgroundColor: "#F1F8E4", padding: "15px 0 15px 0", borderRadius: "10px" }}>
                                    Improve faster by reducing your <br /><span style={{fontSize:"16px"}}>Swing <FontAwesomeIcon icon={faArrowCircleRight}  style={{color:"yellowgreen"}}/> Watch <FontAwesomeIcon icon={faArrowCircleRight}  style={{color:"yellowgreen"}}/> Learn <FontAwesomeIcon icon={faArrowCircleRight}  style={{color:"yellowgreen"}}/> Improve</span><br /> cycle time.
                                </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6 align-self-center text-center" style={{padding: "0px"}}>
                            <img src="./putting_front.png" alt="Any angle" /> 
                        </div></div>
                </div>
            </section>

            <div className="container">
                <div className="row" style={{ backgroundColor: "yellowgreen", padding: "20px 0 20px 0" }}>
                    <div className="col-12 align-self-center text-center">
                        <h3 style={{ margin: '30px 10px 10px 10px', fontSize: '26px', lineHeight: "1.5", color: "white" }}>THE BEST WAY TO TRY PRACTICELOOP?</h3>
                        <p style={{ fontSize: "20px", color: "var(--thm-black)",  marginBottom: "20px" }}>Is with our Presidents Cup Special</p>
                        <div style={{ color: "#EEEEEE", margin: "10px" }}>Get a 90 day extended trial if you install before the Presidents Cup finishes.<br />Redeem code <strong>PRESCUP2024</strong> below.</div>
                        <a href="#" onClick={() => iphoneRedeemCode('PRESCUP2024')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                        <a href="/promo" ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                        <div style={{ color: "#EEEEEE", fontSize: "12px" }}>Normally 7 days. Cancel any time. Offer expires Sept 30 2024.</div>
                    </div>
                </div>
            </div>
            <section id="batting_cricket" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row clearfix align-self-center">

                        <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h2 style={{ margin: '30px 10px 10px 10px', fontSize: '26px', lineHeight: "1.5" }}>
                                    Pause the replay without pausing your practice
                                </h2>
                                <p style={{ fontSize: "16px" }}><strong>Analyse</strong> your swing in more detail by <strong>pausing</strong> the instant replay</p>
                                <p>&nbsp;</p>
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <img src="./driving_range_pause.png" alt="Batting" style={{ width: "100%", maxWidth: "800px" }} ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="batting_cricket" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row clearfix align-self-center">

                        <div className="col-xl-6 col-lg-6 col-12 align-self-center" style={{minHeight:"380px"}}>
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h2 style={{ margin: '30px 10px 30px 10px', fontSize: '26px' }}>
                                    Not just an instant replay <br /><span style={{ color: "yellowgreen" }}>It's a virtual mirror</span>
                                </h2>
                                    <p style={{fontSize:"16px"}} className="text-center my-auto">Use the <strong>Real-Time</strong> stream to see a direct feed from your camera. It acts like a <strong>virtual mirror</strong> that you can carry with you.</p>
                                    <img style={{fontSize:"16px", marginTop: "35px"}} src="./real_time.png" alt="Real time view" ></img>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-12 align-self-center" style={{minHeight:"380px"}}>
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                            <h2 style={{ margin: '30px 10px 10px 10px', fontSize: '26px' }}>
                                    "If you record your swing, you need <span style={{ color: "yellowgreen" }}>PracticeLoop</span>"
                                </h2>    
                                <img src="./chipping.png" style={{width: "300px"}} alt="Better than normal video" ></img>
                                <p style={{fontSize:"16px"}} className="text-center my-auto">Our customers love PracticeLoop and you will too. <br />Start a free trial today.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="features" className="atf-pricing-area atf-section-padding">
                <div className="container">
                    <div className="row clearfix">
                        { 1==2 &&
                        <div className="col-lg-6 col-md-6 atf-pricing-item">
                            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-pricing-heading">
                                    <div className="atf-pricing-name">
                                        <h2 className="offer-list-none">Tripod deal</h2>
                                        <h4>First year free*</h4>
                                    </div>
                                </div>
                                <div className="row atf-pricing-body">
                                    <img className="col-5" src="./tripod_special.png" alt="Real time view" ></img>
                                    <div className="col-7">
                                    <p>Buy our super-light and portable Practice Tripod and get an extended trial* of the PRO subscription.</p>
                                    
                                    <p style={{fontSize: "10px"}}>(*12 months for IPhone.  90 days for Android.)</p>
                                    <button className="btn atf-themes-btn" style={{marginTop: "20px"}}>Shop now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
}
                        <div className="col-lg-12 col-md-12 atf-pricing-item">
                            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <div className="atf-pricing-heading">
                                    <div className="atf-pricing-name">
                                        <h2 className="offer-list-none">PRESIDENTS CUP SPECIAL</h2>
                                        <h4><a href="/promo">First 90 days free</a></h4>
                                        <h2 style={{fontSize: "18px", marginTop: "0px"}}>Then only US$1.99* <span style={{fontSize: '14px'}}>/month</span></h2>
                                    </div>
                                </div>
                                <div className="atf-pricing-body">
                                    <p>We are celebrating the 2024 Presidents Cup by giving you a 90 day extended trial (normally 7 days). </p>
                                    <p style={{padding:"10px 0"}}>Cancel any time during the trial and pay nothing. </p>
                                    <p>Hurry. Offer expires Sept 30 2024.</p>
                                    <a href="#" onClick={() => iphoneRedeemCode('PRESCUP2024')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                                    <a href="/promo"><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                                    <p style={{fontSize: "10px"}}>*Prices vary slightly per region.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="get-started" className="atf-section-padding atf-footer-offset">
                <div className="atf-brand-overlay">
                    <div className="container">
                        <div className="row justify-content-center clearfix wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                            <div className="col-lg-8 col-md-8 col-11 text-center atf-mailchamp-headding atf-mailchamp-subscribe">
                                <h2 className="mb-3 text-center">Don't like specials?</h2>
                                <p>That's ok. Install the app normally here.</p>
                                <div>
                                    <a href="#none" onClick={() => iphoneAppDownload('golf_video_bottom_cta')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                                    <a href="#none" onClick={() => androidAppDownload('golf_video_bottom_cta')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}