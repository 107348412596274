import React from "react";

export const Pricing = (props) => {
  return (<section id="features" className="atf-pricing-area atf-section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-xl-6">
            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
              <h2 className="">Features and Pricing</h2>
            </div>
          </div>
        </div>


        <div className="row clearfix">
          <div className="col-lg-6 atf-pricing-item">
            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" style={{minHeight: '450px'}}>
              <div className="atf-pricing-heading">
                <div className="atf-pricing-name">
                  <div className="atf-pricing-name" style={{textAlign: 'center', display:'inline-block'}}>

                    <div style={{width: '150px'}}>
                      <div style={{border: '1px solid var(--thm-black)', borderRadius: '10px 10px 0 0', padding: '5px', color:'var(--thm-black)'}}>Free</div>
                      <div style={{border: '1px solid var(--thm-black)', backgroundColor:'white', borderTop: '0px', borderRadius: '0 0 10px 10px', padding: '15px'}}> <div style={{color: 'var(--thm-black)', fontSize: '32px', fontWeight: 'bold'}}>$0</div></div>
                    </div>

                    <h4>Basic</h4>
                  </div>
                </div>
              </div>
              <div className="atf-pricing-body">
                <ul>
                  <li>Delayed replay</li>
                  <li className="offer-list-none"><del>Realtime view</del></li>
                  <li className="offer-list-none"><del>Cast to second device</del></li>
                  <li className="offer-list-none"><del>Pause and analyse</del></li>
                  <li className="offer-list-none"><del>Tags</del></li>
                  <li className="offer-list-none"><del>Email support</del></li>
                </ul>
              </div>
              <p style={{fontSize:"11px"}}>&nbsp;</p>
            </div>
          </div>

          <div className="col-lg-6 atf-pricing-item">
            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
              <div className="atf-pricing-heading">
                <div className="atf-pricing-name">
                  <div className="atf-pricing-name" style={{textAlign: 'center'}}>
                    <div style={{display: 'flex'}}>
                      <div style={{flexGrow: 2}}></div>
                      <div>
                        <div style={{border: '1px solid var(--thm-black)', borderRadius: '10px 0 0 0', padding: '5px', color:'var(--thm-black)'}}>Yearly</div>
                        <div style={{border: '1px solid var(--thm-black)', backgroundColor:'var(--thm-black)', borderRadius: '0 0 0 10px', padding: '15px'}}> <div style={{color: 'white', fontSize: '32px', fontWeight: 'bold'}}>$7.99*</div></div>
                      </div>
                      <div>
                        <div style={{border: '1px solid var(--thm-black)', borderRadius: '0 10px 0 0', padding: '5px', color:'var(--thm-black)'}}>Monthly</div>
                        <div style={{border: '1px solid var(--thm-black)', backgroundColor:'#783F8EC0', borderRadius: '0 0 10px 0', padding: '15px'}}> <div style={{color: 'white', fontSize: '32px', fontWeight: 'bold'}}>$0.99*</div></div>
                      </div>
                      <div style={{flexGrow: 2}}></div>
                    </div>
                    <h4>Pro</h4>

                  </div>
                </div>
              </div>
              <div className="atf-pricing-body">
                <ul>
                  <li>Delayed replay</li>
                  <li>Realtime view</li>
                  <li>Cast to second device</li>
                  <li>Pause and analyse</li>
                  <li>Tags</li>
                  <li>Email support</li>
                </ul>
              </div>
              <p style={{fontSize:"11px"}}>* USD. Prices vary per region.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )}
