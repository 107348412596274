import React from 'react';
import LogoText from './components/logoText';
import { androidAppDownload, iphoneAppDownload } from './link-actions';

function InstallApp(props) {
    return (
        <>
            <div className="non-landing-banner"></div>
            <div style={{ width: '100%', height: '100%', textAlign: 'center', paddingTop: '10vh' }}>
                <img src="logo_dark.png" alt="Logo" style={{ width: '70px', height: 'auto', display: 'inline-block', marginBottom: '5px' }} />
                <br />
                <LogoText style={{ fontSize: "32px" }}></LogoText>
                <div style={{ paddingTop: '10vh' }}>
                    <p>Install PracticeLoop from your preferred app store:</p>
                    <div className="col-12 text-center cta-panel">
                        <a href="#none" onClick={() => iphoneAppDownload('install')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                        <a href="#none" onClick={() => androidAppDownload('install')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InstallApp;

