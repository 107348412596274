import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMinusCircle } from '@fortawesome/free-solid-svg-icons'


function ProCompareTable(props) {
    let basic = <span>Basic</span>
    let pro = <span>Pro</span>
    let asterisk = <span></span>

    if (props.cost) {
        basic = <span>Basic - FREE</span>
        pro = <span>Pro - $29/yr<span style={{fontSize: '10px'}}>*</span></span>
        asterisk = <span style={{fontSize: '10px'}}>* Yearly subscription.  Price in AUD. Includes {props.trialDays ?? '7'} day trial.</span>
    }
    return (
        <div>
            <table className="compare-table">
                <tbody>
                    <tr>
                        <th style={{ width: '50%' }}>Features</th>
                        <th>{basic}</th>
                        <th>{pro}</th>
                    </tr>
                    <tr>
                        <td>Realtime video stream</td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                    </tr>
                    <tr>
                        <td>Delayed video stream</td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                    </tr>
                    <tr>
                        <td>Pause delayed video</td>
                        <td><FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon></td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                    </tr>
                    <tr>
                        <td>Checkpoints</td>
                        <td><FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon></td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                    </tr>
                    <tr>
                        <td>YouTube compare</td>
                        <td><FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon></td>
                        <td><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></td>
                    </tr>
                </tbody>
            </table>
            {asterisk}
        </div>
    );
}


export default ProCompareTable;