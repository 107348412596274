import React, { } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo, faMobileAlt, faTabletAlt, faLaptop } from '@fortawesome/free-solid-svg-icons'
import { iphoneAppDownload, androidAppDownload } from './../link-actions';

const Golf = () => {
    return (
        <>
            <section id="home_cricket" className="atf-cover-bg atf-hero-area atf-align-items-details aft-width-two" style={{ height:"600px", backgroundImage: "url(assets/img/bg/bg.jpg)", backgroundSize: "cover", backgroundPosition: "center center" }}>
                <div className="container">
                    <div className="row atf-content-details align-items-center">
                        <div className="col-lg-6 col-md-6 col-12 atf_home_img text-center">
                            <div className="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.4s" data-wow-offset="0">
                                <video id="loopvideo" preload="metadata" poster="/video_hero_golf_cover.png" style={{ width: "100%", maxWidth: "800px", boxShadow: '0px 0px 30px 20px #EEEEEE66' }}
                                    controls={true} autoPlay={true} muted={true} playsInline={true}>
                                    <source src="/video_hero_golf.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div style={{color: "white"}}>(Play video)</div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 atf-single-details text-lg-left text-center">
                            <p>&nbsp;</p>
                            <h2>Improve your golf. Faster!</h2>
                            <div className="col-12 text-center cta-panel">
                                <a href="#none" onClick={() => iphoneAppDownload('top_banner_golf')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                                <a href="#none" onClick={() => androidAppDownload('top_banner_golf')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                            </div>
                            <p>&nbsp;</p>

                        </div>

                    </div>
                </div>
            </section>

            <section id="how" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-8">
                            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <h2>What is PracticeLoop?</h2>
                                <div className="animated-line"></div>
                                <p><strong>PracticeLoop</strong> is an app that streams video from your phone, and shows both a <strong>real-time view</strong> and <strong>instant replay</strong> on a second device.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row clearfix align-self-center">

                        <div className="col-xl-6 col-lg-6 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <div className="atf-about-image"><img src="hero_transparent_2.png" alt="about" className="card-s" /></div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 align-self-center">
                            <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-section-title atf-feature-content">
                                    <div className="atf-single-feature">
                                        <div className="atf-feature-section">
                                            <div className="atf-feature-icon atf-feature-heading">
                                                <h3>1. Set up your devices</h3></div>
                                            <div className="atf-feature-text text-left">
                                                <p>Run PracticeLoop on your phone.  Then point it at your practice area.</p>
                                                <p className="highlight">Connect a second device. Then place it on the ground.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-section-title atf-feature-content">
                                    <div className="atf-single-feature">
                                        <div className="atf-feature-section">
                                            <div className="atf-feature-icon atf-feature-heading">
                                                <h3>2. Check your setup</h3></div>
                                            <div className="atf-feature-text">
                                                <p>Live video from your phone will be shown on the second device.</p>
                                                <p className="highlight">Use it like a mirror to check your setup position.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-section-title atf-feature-content">
                                    <div className="atf-single-feature">
                                        <div className="atf-feature-section">
                                            <div className="atf-feature-icon atf-feature-heading">
                                                <h3>3. Take a swing</h3></div>
                                            <div className="atf-feature-text">
                                                <p>Swing, then look down to watch the instant replay on the second device. Pause it to analyse in more detail.</p>
                                                <p className="highlight">Then learn, improve, and repeat.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            <section id="golf_start" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">

                    <div className="row clearfix align-self-center">

                        <div className="col-xl-6 col-lg-6 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h2 style={{ margin: "30px 0 30px 0" }}>PracticeLoop helps you improve your swing<br /><span style={{ color: "yellowgreen" }}>By giving instant visual feedback</span></h2>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">

                                <div style={{ display: 'inline-block', width: '80%', height: 'auto', maxWidth: '700px', position: 'relative', paddingBottom: '20px' }}>
                                    <img src="/ipad frame.png" style={{ position: 'relative', width: '100%', height: 'auto' }} alt="Ipad example" />
                                    <video id="loopvideo" style={{ position: 'absolute', width: '83%', left: '0px', marginTop: '4%', marginLeft: '8%' }} controls={true} autoPlay={true} muted={true} loop={true} playsInline={true}>
                                        <source src="/screen view.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                                    <div style={{paddingTop: "10px"}}>Watch both a real-time, and delayed stream, right in front of your eyes.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-self-center" style={{ backgroundColor: "yellowgreen", padding: "40px 0 40px 0" }}>
                        <div className="col-xl-8 col-lg-8 text-center">
                            <h4>Visual feedback is crucial for improvement.<br />That's why...</h4>
                            <h2 style={{ color: "white" }}>Golf coaches have been using video and mirrors for years</h2>
                            <p>&nbsp;</p>
                            <h3>But we think PracticeLoop is better!</h3>
                        </div>
                    </div>
                </div>
            </section>


            

            <section id="batting_cricket" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-8">
                            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <h2>Better than normal video</h2>
                                <div className="animated-line"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row clearfix align-self-center">
                        <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <div style={{ margin: '30px 10px' }}>
                                    PracticeLoop videos from your phone, and shows a slightly delayed replay on a second device.  You can even pause it to analyse in more detail.
                                </div>
                                <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                    <div className="atf-section-title atf-feature-content">
                                        <div className="atf-single-feature">
                                            <div className="atf-feature-section atf-feature-section-text">
                                                <h4 style={{ color: "yellowgreen" }}>Don't walk back to your camera between shots!</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                    <div className="atf-section-title atf-feature-content">
                                        <div className="atf-single-feature">
                                            <div className="atf-feature-section atf-feature-section-text">
                                                <h4 style={{ color: "yellowgreen" }}>Don't muck about on your phone trying to find and watch the video!</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                    <div className="atf-section-title atf-feature-content">
                                        <div className="atf-single-feature">
                                            <div className="atf-feature-section atf-feature-section-text">
                                                <h4 style={{ color: "yellowgreen" }}>Don't forget what it "felt" like before you watch the video!</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 style={{ margin: '30px 10px' }}>
                                    With PracticeLoop, see a replay of your swing immediately, right in front of your eyes.
                                </h3>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <img src="./golf_athlete_better_than_video.png" alt="Batting" style={{ width: "100%", maxWidth: "800px" }} ></img>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="batting_cricket" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-8">
                            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <h2>Better than a mirror</h2>
                                <div className="animated-line"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row clearfix align-self-center">
                        <div className="col-xl-7 col-lg-7 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <div style={{ margin: '30px 10px' }}>
                                    PracticeLoop videos from your phone, and shows the real-time video on a second device.
                                </div>
                                <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                    <div className="atf-section-title atf-feature-content">
                                        <div className="atf-single-feature">
                                            <div className="atf-feature-section atf-feature-section-text">
                                                <h4 style={{ color: "yellowgreen" }}>Don't move your head to see your reflection!</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                    <div className="atf-section-title atf-feature-content">
                                        <div className="atf-single-feature">
                                            <div className="atf-feature-section atf-feature-section-text">
                                                <h4 style={{ color: "yellowgreen" }}>You can't carry a mirror to the range!</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 style={{ margin: '30px 10px' }}>
                                    With PracticeLoop, see your "virtual reflection" from any angle, without moving your head.
                                </h3>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-12 align-self-center">
                            <div className="atf-single-about-img text-center my-auto wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <img src="./golf_athlete_better_than_mirror.png" alt="Batting" style={{ width: "100%", maxWidth: "800px" }} ></img>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <div className="row" style={{ backgroundColor: "yellowgreen", padding: "20px 0 20px 0" }}>
                <div className="col-12 align-self-center text-center">
                    <h1>Get the edge over your golf buddies</h1>
                    <div>Install the app for free</div>
                    <a href="#none" onClick={() => iphoneAppDownload('golf_cta_1')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                    <a href="#none" onClick={() => androidAppDownload('golf_cta_1')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>

                </div>
            </div>

            <section id="batting_cricket" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-8">
                            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <h2>How well do you know your swing?</h2>
                                <div className="animated-line"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row clearfix align-self-center">
                        <div className="col-xl-4 col-lg-4 col-12 align-self-center">
                            <div style={{ backgroundImage: 'url(\'/ipad_carpet.png\')', display: 'inline-block', alignItems: 'center', width: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                <div className="columns2" style={{ alignItems: 'center', gap: '0px' }}>
                                    <div style={{ padding: '30px' }}>
                                        <h2 style={{ color: 'white' }}>Do you move your hips when you putt?</h2>
                                    </div>
                                    <div>
                                        <img src="/ipad_putting.png" alt="Putt at home" style={{ padding: '30px' }} ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12 align-self-center">
                            <div className="columns2" style={{ marginTop: '20px', alignItems: 'center', gap: '0px' }} >
                                <div style={{ padding: '30px' }}>
                                    <h2>Are you set up correctly?</h2>
                                </div>
                                <div>
                                    <img src="/ipad_setup.png" alt="Setup properly" style={{ padding: '30px' }} ></img>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12 align-self-center">
                            <div style={{ display: 'inline-block', alignItems: 'center', width: '100%', backgroundImage: 'url(\'/ipad_grass.png\')', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                <div className="columns2" style={{ alignItems: 'center', gap: '0px' }}>
                                    <div style={{ padding: '30px' }}>
                                        <h2 style={{ color: 'white' }}>Do you over-swing?</h2>
                                    </div>
                                    <div className="pad">
                                        <img src="/ipad_overswing.png" alt="Overswinging" style={{ padding: '20px' }}  ></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <div className="row" style={{ backgroundColor: "yellowgreen", padding: "20px 0 20px 0" }}>
                <div className="col-12 align-self-center text-center">
                    <h1>Watch. Learn and improve. Repeat.</h1>
                    <div>Install the app for free</div>
                    <a href="#none" onClick={() => iphoneAppDownload('golf_cta_1')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                    <a href="#none" onClick={() => androidAppDownload('golf_cta_1')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>

                </div>
            </div>
            <section id="batting_cricket" className="atf-about-area atf-section-padding atf-shape">
                <img className="atf-shape-one" src="assets/img/shape/shape1.png" alt="" />
                <div className="container">
                    <div className="row justify-content-center align-self-center text-center">
                        <div className="col-xl-8 col-lg-8">
                            <div className="home-image-box">
                                <div style={{ marginTop: '10px', marginBottom: '25px' }}>
                                    <h3>The practice feedback loop</h3>
                                </div>

                                <div>
                                    <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'var(--thm-color)', fontSize: '60px' }}></FontAwesomeIcon>
                                    <h3 className="shake-1">Execute</h3>
                                    <p>Take a swing in front of the camera</p>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faRedo} className="loopSpinner"></FontAwesomeIcon>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faLaptop} style={{ color: 'var(--thm-color)', fontSize: '40px', marginBottom: '5px' }}></FontAwesomeIcon>
                                    <FontAwesomeIcon icon={faTabletAlt} style={{ color: 'var(--thm-color)', fontSize: '60px', margin: '0px 20px 0 20px' }}></FontAwesomeIcon>
                                    <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'var(--thm-color)', fontSize: '40px', margin: '0px 25px 5px 5px' }}></FontAwesomeIcon>
                                    <h3 className="shake-2">Learn and improve</h3>
                                    <p>Watch, learn, improve, and repeat.</p>
                                </div>
                                <h3 style={{ marginTop: '45px' }}>PracticeLoop speeds up this loop, so you can improve faster</h3>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>

            <section id="who-for" className="atf-setup-area atf-section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-6">
                            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <h2>For golfers, coaches, and parents.</h2>
                                <div className="animated-line"></div>
                                <p className="mx-auto my-auto">PracticeLoop helps you learn and teach.</p>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-12 align-self-center">
                            <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-section-title atf-feature-content">
                                    <div className="atf-single-feature">
                                        <div className="atf-feature-section">
                                            <div className="atf-feature-icon atf-feature-heading">
                                                <i className="fa fa-users"></i><h3>Golfers</h3></div>
                                            <div className="atf-feature-text text-left">
                                                <p>Get important visual feedback to ensure you are swinging how you think you are.</p>
                                                <p>See this feedback immediately so you can improve faster.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12 align-self-center">
                            <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-section-title atf-feature-content">
                                    <div className="atf-single-feature">
                                        <div className="atf-feature-section">
                                            <div className="atf-feature-icon atf-feature-heading">
                                                <i className="fa fa-clipboard-list"></i><h3>Coaches</h3></div>
                                            <div className="atf-feature-text text-left">
                                                <p>Players 'get' what you are teaching them faster if they can see it.</p>
                                                <p>Make visual checkpoints to stop regression once the coaching session is over.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12 align-self-center">
                            <div className="atf-about-content-area  wow fadeIn m-auto" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="atf-section-title atf-feature-content">
                                    <div className="atf-single-feature">
                                        <div className="atf-feature-section">
                                            <div className="atf-feature-icon atf-feature-heading">
                                                <i className="fa fa-user"></i> <i className="fa fa-user" style={{ fontSize: '30px' }}></i><h3>Parents</h3></div>
                                            <div className="atf-feature-text text-left">
                                                <p>Your child knows what a good swing looks like. They just don't know what their swing looks like.</p>
                                                <p>Give that awareness and watch them thrive.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="atf-setup-area atf-section-padding">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <h2>QUICK SETUP</h2>
                                <div className="animated-line"></div>
                                <p className="mx-auto my-auto">Watch this short video to learn how to set up PracticeLoop</p>
                            </div>

                            <video id="loopvideo" preload="metadata" style={{ marginTop: '25px', width: "100%", maxWidth: "400px", boxShadow: '0px 0px 20px 10px #66666666' }}
                                controls={true} autoPlay={false} muted={false} loop={false} playsInline={true}>
                                <source src="/setup_video.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row">
                <div className="col-12 align-self-center text-center">
                    <h1>Ready to start improving?</h1>
                </div>
            </div>

            <div id="get-started" className="atf-section-padding atf-footer-offset">
                <div className="atf-brand-overlay">
                    <div className="container">
                        <div className="row justify-content-center clearfix wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                            <div className="col-lg-8 col-md-8 col-11 text-center atf-mailchamp-headding atf-mailchamp-subscribe">
                                <h2 className="mb-3 text-center">Install the app to get started</h2>
                                <div>
                                    <a href="#none" onClick={() => iphoneAppDownload('golf_bottom_cta')} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                                    <a href="#none" onClick={() => androidAppDownload('golf_bottom_cta')} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Golf;

