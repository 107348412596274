import {androidAppDownload, iphoneAppDownload} from "../link-actions";
import React from "react";

export const PricingCricket = (props) => {
  return (<section id="features" className="atf-pricing-area atf-section-padding">
      <div id="features-cricket" className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-xl-6">
            <div className="text-center">
              <h2 style={{color: 'yellowgreen', marginBottom: '30px'}}>Features and Pricing</h2>
            </div>
          </div>
        </div>


        <div className="row clearfix">
          <div className="col-lg-4 atf-pricing-item">
            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" style={{minHeight: '450px'}}>
              <div className="atf-pricing-heading">
                <div className="atf-pricing-name">
                  <h2>$12.99* <span style={{fontSize: '14px'}}>/year</span></h2>

                  <h4>Individual</h4>
                </div>
              </div>
              <div className="atf-pricing-body">
                <ul>
                  <li>Single user</li>
                  <li>Great for individual coaches</li>
                  <li>Great for players and parents</li>
                  <li>Email support</li>
                  <li>Try free for 7 days</li>
                </ul>
              </div>
              <div style={{marginTop: '14px'}}>
                <a href="#none" onClick={() => iphoneAppDownload(`${props.key}_cta_1`)} ><img src="Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Download on the App Store" style={{ height: '65px', padding: '11px 10px 11px 10px' }} /></a>
                <a href="#none" onClick={() => androidAppDownload(`${props.key}_cta_1`)} ><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{ height: '65px' }} /></a>
              </div>
              <p style={{fontSize:"11px"}}>* USD. Prices vary slightly per region.</p>
            </div>
          </div>

          <div className="col-lg-4 atf-pricing-item">
            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
              <div className="atf-pricing-heading">
                <div className="atf-pricing-name">
                  <h2>$29.99* <span style={{fontSize: '14px'}}>/year</span></h2>
                  <h4>Coaches pack</h4>
                </div>
              </div>
              <div className="atf-pricing-body">
                <ul>
                  <li>Up to 6 coaches or players</li>
                  <li>Give access to whole coaching group</li>
                  <li>All features included</li>
                  <li>Email support</li>
                  <li>Try free for 7 days</li>
                </ul>
              </div>
              <h2 style={{marginTop: '20px'}}>
                <a style={{backgroundColor:"var(--thm-black)", margin:"10px", borderRadius:"10px", fontSize:"14px", color:"white", padding:"10px 35px"}} href={'mailto:help@practiceloop.com?subject=Coaches Pack&body=Hi,%0D%0A%0D%0AI am interested in the PracticeLoop Coaches Pack, please send me some more information.%0D%0A%0D%0AName:%0D%0ASport (golf, cricket, etc.):%0D%0ARegards.'}>Buy</a>
              </h2>
              <p style={{fontSize:"11px"}}>* USD. Prices vary slightly per region.</p>
            </div>
          </div>

          <div className="col-lg-4 atf-pricing-item">
            <div className="atf-pricing-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" style={{minHeight: '450px'}}>
              <div className="atf-pricing-heading">
                <div className="atf-pricing-name">
                  <h2>$69.99* <span style={{fontSize: '14px'}}>/year</span></h2>
                  <h4>Club pack</h4>
                </div>
              </div>
              <div className="atf-pricing-body">
                <ul>
                  <li>Up to 70 coaches or players</li>
                  <li>Great value for your whole club</li>
                  <li><strong>Portable tripod included</strong></li>
                  <li>Email support</li>
                  <li>Try free for 7 days</li>
                </ul>
              </div>
              <h2 style={{marginTop: '20px'}}>
                <a style={{backgroundColor:"var(--thm-black)", margin:"10px", borderRadius:"10px", fontSize:"14px", color:"white", padding:"10px 35px"}} href={'mailto:help@practiceloop.com?subject=Club Pack&body=Hi,%0D%0A%0D%0AI am interested in the PracticeLoop Club Pack, please send me some more information.%0D%0A%0D%0AName:%0D%0ASport (golf, cricket, etc.):%0D%0ARegards.'}>Buy</a>
              </h2>
              <p style={{fontSize:"11px"}}>* USD. Prices vary slightly per region.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )}
