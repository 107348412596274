import React from "react";

export const SingleSection = (props) => {
  return (<section className="atf-about-area atf-section-padding" style={{backgroundColor: props.background}}>
    <div className="container">
      <div className="row clearfix align-self-center">
        <div className="col-12 align-self-center">
          {props.children}
        </div>
      </div>
    </div>
  </section>)
}