import React from "react";

export const DoubleSection = (props) => {
  return (
<section className="atf-about-area atf-section-padding" style={{backgroundColor: props.background}}>
  <div className="container">
    <div className="row clearfix align-self-center">

      <div className="col-xl-6 col-lg-6 col-12 align-self-center">
        {props.col1}
      </div>

      <div className="col-xl-6 col-lg-6 col-12 align-self-center">
        {props.col2}
      </div>
    </div>
  </div>
</section>)
}